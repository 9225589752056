import { useCallback, useEffect, useRef } from 'react';

import type { SwiperClass } from 'swiper/react';

export const useSwiperNavigation = () => {
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const swiperRef = useRef<SwiperClass | null>(null);

  const onSwiper = useCallback((swiper: SwiperClass) => {
    swiperRef.current = swiper;
  }, []);

  useEffect(() => {
    const swiper = swiperRef.current;
    const prevBtn = prevBtnRef.current;
    const nextBtn = nextBtnRef.current;

    const onSlideChange = (swiper: SwiperClass) => {
      if (prevBtnRef.current) {
        prevBtnRef.current.disabled =
          !swiper.allowSlidePrev || swiper.isBeginning;
      }

      if (nextBtnRef.current) {
        nextBtnRef.current.disabled = !swiper.allowSlideNext || swiper?.isEnd;
      }
    };

    const slideNext = () => {
      swiperRef.current?.slideNext();
      swiperRef?.current && onSlideChange(swiperRef?.current);
    };

    const slidePrev = () => swiperRef.current?.slidePrev();

    prevBtn?.addEventListener('click', slidePrev);
    nextBtn?.addEventListener('click', slideNext);
    swiper?.on('slideChange', onSlideChange);

    if (swiper && globalThis) {
      onSlideChange(swiper);
      window?.addEventListener('resize', () => swiper.update());

      return () => {
        prevBtn?.removeEventListener('click', slidePrev);
        nextBtn?.removeEventListener('click', slideNext);
        swiper?.off('slideChange', onSlideChange);
        window?.removeEventListener('resize', () => () => swiper.update());
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperRef?.current, prevBtnRef?.current, nextBtnRef?.current]);

  return { nextBtnRef, onSwiper, prevBtnRef, swiperRef };
};
