export interface IMedia {
  img: string;
  url?: string;
}

export const MEDIA_LIST: IMedia[] = [
  {
    img: 'bitcoin.svg',
    url: 'https://www.bitcoin.com/',
  },
  {
    img: 'bloomberg.svg',
    url: 'https://www.bloomberg.com/press-releases/2023-02-15/cryptomus-the-all-in-one-solution-for-accepting-cryptocurrency-payments',
  },
  {
    img: 'yahoo-finance.svg',
    url: 'https://finance.yahoo.com/news/cryptomus-one-solution-accepting-cryptocurrency-190000004.html',
  },
  {
    img: 'benzinga.svg',
    url: 'https://www.benzinga.com/amp/content/30930648',
  },
  {
    img: 'ton.svg',
    url: 'https://t.me/toncoin/668',
  },
  {
    img: 'cointelegraph.svg',
    url: 'https://cointelegraph.com/press-releases/cryptocurrency-payment-processor-cryptomus-is-ready-to-become-a-game-changer-in-the-market',
  },
  {
    img: 'morningstar.svg',
    url: 'https://www.morningstar.com/',
  },
  {
    img: 'beincrypto.png',
    url: 'https://beincrypto.com/cryptomus-officially-records-over-100000-transactions/',
  },
  {
    img: 'market-watch.svg',
    url: 'https://www.marketwatch.com/',
  },
  {
    img: 'chaingpt.svg',
    url: 'https://www.chaingpt.org/',
  },
  {
    img: 'adspower.svg',
    url: 'https://www.adspower.com/ru/?source=Cryptomus',
  },
  {
    img: 'aeza.svg',
    url: 'https://aeza.net/?ref=cryptomus',
  },
  {
    img: 'sendpulse.svg',
    url: 'https://sendpulse.com/?utm_source=cryptomus.com&utm_medium=referral&utm_campaign=cryptomus-integration%25C2%25AO%7C',
  },
  {
    img: 'mercuryo.svg',
    url: 'https://mercuryo.io/',
  },
  {
    img: 'DEXTools.svg',
    url: 'https://www.dextools.io/app/polygon/pair-explorer/0xe8a715d97388fab85ab0cff707a63dd79096c9e9?t=1711008168862',
  },
  {
    img: 'bestchange.svg',
    url: 'https://bestchange.com/ ',
  },
];
