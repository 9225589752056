import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { getOrganizationJSON } from './lib/get-organization-json';
import { getSoftwareApplicationJSON } from './lib/get-software-application-json';

import { useTranslation } from 'next-i18next';
import type { WithContext } from 'schema-dts';

import { getLocaleLink } from 'utils/get-locale-link';

interface IAdditionalMarkup {
  displayCondition: boolean;
  id: string;
  jsonLD: WithContext<any>;
}

interface Props {
  title: string;
  additionalData?: React.ReactNode;
  additionalMarkup?: IAdditionalMarkup[];
  description?: string;
  hideMicroMarkup?: boolean;
  image?: string;
  noIndex?: boolean;
  url?: string;
}

interface AlternateLink {
  href: string;
  hreflang: string;
}

const SEOHead = ({
  title,
  description,
  image,
  url,
  additionalData,
  noIndex = false,
  hideMicroMarkup,
  additionalMarkup,
}: Props) => {
  const siteName = 'https://cryptomus.com';
  const router = useRouter();
  const { asPath, locale, locales, query, route } = router;
  const { i18n } = useTranslation();
  const urlJSON = getLocaleLink('https://cryptomus.com/', i18n.language);
  const organizationJSON = getOrganizationJSON(urlJSON);
  const softwareApplicationJSON = getSoftwareApplicationJSON(urlJSON);

  const prepareMarkupScriptArray = () => {
    const microMarkupScripts: IAdditionalMarkup[] = [
      {
        displayCondition: !hideMicroMarkup,
        id: 'organizationLD',
        jsonLD: organizationJSON,
      },
      {
        displayCondition: !hideMicroMarkup,
        id: 'softwareApplicationJSON',
        jsonLD: softwareApplicationJSON,
      },
    ];

    if (!additionalMarkup) return microMarkupScripts;

    return [...microMarkupScripts, ...additionalMarkup];
  };

  // input -> url/[slug]/ or url/[[...slug]]
  // output -> url/
  function cutSlugFromString(str) {
    const slugRegex = /\[slug\/?|(\[\[\.\.\.slug])\/?/g;

    return str.replace(slugRegex, '');
  }

  const newRoute = cutSlugFromString(route);
  const slug = query.slug; // Get the slug from the router query

  const finalURL = `${siteName}${
    locale === 'en' ? '' : `/${locale}`
  }${newRoute}${slug ? `${slug}` : ''}`;

  const alternateLangLinks: AlternateLink[] =
    locales?.map((loc) => ({
      href: `${url}/${loc}${asPath}`,
      hreflang: loc,
    })) || [];

  const renderLink = (hrefLang: string) =>
    `${siteName}${hrefLang === 'en' ? '' : `/${hrefLang}`}${newRoute}${slug ? `${slug}` : ''}`;

  const alternateLinkTags = alternateLangLinks.map((alternateLink) => (
    <link
      key={alternateLink.hreflang}
      href={renderLink(alternateLink.hreflang)}
      hrefLang={alternateLink.hreflang}
      rel='alternate'
    />
  ));

  return (
    <Head>
      {/* BASE */}
      <title>{title}</title>
      <meta content={description} name='description' />

      {/* Open Graph */}
      <meta content={title} property='og:title' />
      <meta content={description} property='og:description' />
      <meta content='website' property='og:type' />
      <meta content={finalURL} property='og:url' />
      <meta content={title} property='og:image:alt' />
      {image && <meta content={image} property='og:image' />}

      {/* Twitter */}
      <meta content='summary_large_image' name='twitter:card' />
      <meta content={title} name='twitter:title' />
      <meta content={description} name='twitter:description' />
      {image && <meta content={image} name='twitter:image' />}

      {/* NO INDEX */}
      {noIndex && <meta content='noindex, nofollow' name='robots' />}

      {/* URL */}
      <link href={finalURL} rel='canonical' />

      {alternateLinkTags}

      {/* Extra */}
      {additionalData}

      {prepareMarkupScriptArray().map(
        (item) =>
          item?.displayCondition && (
            <script
              key={item.id}
              type='application/ld+json'
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(item.jsonLD),
              }}
            />
          )
      )}
    </Head>
  );
};

export default SEOHead;
