import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import InfiniteScroll from 'ui/infinite-scroll/infinite-scroll';

import type { IMedia } from 'constants/media-list';
import { MEDIA_LIST } from 'constants/media-list';

import styles from 'app/styles/index.module.scss';

const MediaBlock: React.FC = () => {
  const { i18n } = useTranslation(['common', 'roadmap', 'blog']);

  const getMediaList = (): IMedia[] => {
    if (i18n.language === 'ru')
      return MEDIA_LIST.map((item) => {
        if (item?.url?.includes('bestchange'))
          return { ...item, url: item?.url?.replace('.com', '.ru') };

        return item;
      });

    return MEDIA_LIST;
  };

  return (
    <div className={styles.media}>
      <div className={styles.infinite_container}>
        <InfiniteScroll
          items={getMediaList()}
          renderItem={(item: IMedia) => (
            <a
              key={item.img}
              aria-label='Read morea about coin'
              className={clsx(styles.infinite_item, styles.main)}
              href={item.url}
              target='_blank'
            >
              <img
                alt={item.img}
                height={item?.url?.includes('bestchange') ? 40 : undefined}
                src={`/icons/press/${item.img}`}
              />
            </a>
          )}
        />
      </div>
    </div>
  );
};

export default MediaBlock;
