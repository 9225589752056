import React from 'react';

import type { ReactNode } from 'react';

import styles from './infinite-scroll.module.scss';

interface IInfiniteScrollProps<T> {
  items: T[];
  renderItem: (item: T) => ReactNode;
}

const InfiniteScroll = <T,>({ items, renderItem }: IInfiniteScrollProps<T>) => (
  <div className={styles.scroll_parent}>
    <div className={styles.scroll_element}>
      {items.map((item) => renderItem(item))}
    </div>
    <div className={styles.scroll_element}>
      {items.map((item) => renderItem(item))}
    </div>
  </div>
);

export default InfiniteScroll;
