import type { SoftwareApplication, WithContext } from 'schema-dts';

export const getSoftwareApplicationJSON = (
  url: string
): WithContext<SoftwareApplication> => ({
  '@context': 'https://schema.org',
  '@type': 'SoftwareApplication',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingCount: '17438',
    ratingValue: '4.7232186',
  },
  applicationCategory: 'FinanceApplication',
  author: {
    '@type': 'Organization',
    name: 'Cryptomus',
    url,
  },
  description:
    ' Cryptomus provides a payment gateway, wallet, staking for rewards, P2P exchange, and a cryptocurrency converter supporting many currencies for users.',
  name: 'Cryptomus',
  offers: [
    {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '0',
      priceCurrency: 'USD',
    },
  ],
  operatingSystem: 'Android, iOS',
  url: ' https://apps.apple.com/ru/app/cryptomus/id6464404665',
});
