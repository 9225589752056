import type { Organization, WithContext } from 'schema-dts';

export const getOrganizationJSON = (
  url: string
): WithContext<Organization> => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  alternateName: 'cryptomus.com',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      contactType: 'Customer Support',
      email: 'support@cryptomus.com',
    },
    {
      '@type': 'ContactPoint',
      contactType: 'Management',
      email: 'robert@cryptomus.com',
    },
  ],
  description:
    ' Cryptomus provides a payment gateway, wallet, staking for rewards, P2P exchange, and a cryptocurrency converter supporting many currencies for users.',
  name: 'Cryptomus',
  sameAs: [
    'https://t.me/cryptomus',
    'https://www.reddit.com/user/Cryptomuscom/',
    'https://www.youtube.com/@cryptomus',
    'https://www.linkedin.com/company/cryptomusgateway/',
    'https://www.facebook.com/cryptomus',
    'https://instagram.com/cryptomus',
    'https://apps.apple.com/app/cryptomus/id6464404665',
    'https://skynet.certik.com/projects/cryptomus',
    'https://www.trustpilot.com/review/cryptomus.com',
  ],
  url,
});
